exports.components = {
  "component---src-layouts-actor-tsx": () => import("./../../../src/layouts/actor.tsx" /* webpackChunkName: "component---src-layouts-actor-tsx" */),
  "component---src-layouts-spectacle-tsx": () => import("./../../../src/layouts/spectacle.tsx" /* webpackChunkName: "component---src-layouts-spectacle-tsx" */),
  "component---src-layouts-wpis-tsx": () => import("./../../../src/layouts/wpis.tsx" /* webpackChunkName: "component---src-layouts-wpis-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aktualnosci-tsx": () => import("./../../../src/pages/aktualnosci.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-tsx" */),
  "component---src-pages-deklaracja-dostepnosci-tsx": () => import("./../../../src/pages/deklaracja-dostepnosci.tsx" /* webpackChunkName: "component---src-pages-deklaracja-dostepnosci-tsx" */),
  "component---src-pages-dofinansowania-tsx": () => import("./../../../src/pages/dofinansowania.tsx" /* webpackChunkName: "component---src-pages-dofinansowania-tsx" */),
  "component---src-pages-fundacja-teatru-klasyki-polskiej-tsx": () => import("./../../../src/pages/fundacja-teatru-klasyki-polskiej.tsx" /* webpackChunkName: "component---src-pages-fundacja-teatru-klasyki-polskiej-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-o-teatrze-tsx": () => import("./../../../src/pages/o-teatrze.tsx" /* webpackChunkName: "component---src-pages-o-teatrze-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-press-room-tsx": () => import("./../../../src/pages/press-room.tsx" /* webpackChunkName: "component---src-pages-press-room-tsx" */),
  "component---src-pages-repertuar-tsx": () => import("./../../../src/pages/repertuar.tsx" /* webpackChunkName: "component---src-pages-repertuar-tsx" */),
  "component---src-pages-rodo-tsx": () => import("./../../../src/pages/rodo.tsx" /* webpackChunkName: "component---src-pages-rodo-tsx" */),
  "component---src-pages-sceny-tsx": () => import("./../../../src/pages/sceny.tsx" /* webpackChunkName: "component---src-pages-sceny-tsx" */),
  "component---src-pages-spektakle-dla-instytucji-tsx": () => import("./../../../src/pages/spektakle-dla-instytucji.tsx" /* webpackChunkName: "component---src-pages-spektakle-dla-instytucji-tsx" */),
  "component---src-pages-spektakle-dla-szkol-tsx": () => import("./../../../src/pages/spektakle-dla-szkol.tsx" /* webpackChunkName: "component---src-pages-spektakle-dla-szkol-tsx" */),
  "component---src-pages-spektakle-tsx": () => import("./../../../src/pages/spektakle.tsx" /* webpackChunkName: "component---src-pages-spektakle-tsx" */),
  "component---src-pages-strefa-wiedzy-tsx": () => import("./../../../src/pages/strefa-wiedzy.tsx" /* webpackChunkName: "component---src-pages-strefa-wiedzy-tsx" */),
  "component---src-pages-wyniki-wyszukiwania-tsx": () => import("./../../../src/pages/wyniki-wyszukiwania.tsx" /* webpackChunkName: "component---src-pages-wyniki-wyszukiwania-tsx" */),
  "component---src-pages-zespol-tsx": () => import("./../../../src/pages/zespol.tsx" /* webpackChunkName: "component---src-pages-zespol-tsx" */)
}

