import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Breakpoints as BreakpointsType } from 'types/theme'
import { breakpoints as themeBreakpoints } from 'styles/theme'

type BreakpointsBoolean = {
  [key in keyof BreakpointsType]: boolean
}
type BreakpointsState = BreakpointsBoolean & {
  activeBreakpoint: keyof BreakpointsType
}
type BreakpointsContextType = BreakpointsState

const DEFAULT_STATE: BreakpointsState = {
  base: true,
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  xxl: false,
  xxxl: false,
  huge: false,
  growStop: false,
  activeBreakpoint: 'xs',
}

const BreakpointsContext = createContext<BreakpointsContextType>({
  ...DEFAULT_STATE,
})

const getBreakpoints = () => {
  const isSSR = typeof window === `undefined`
  const bps: Partial<BreakpointsBoolean> = {}

  const keys = Object.keys(themeBreakpoints) as Array<keyof BreakpointsType>
  keys.forEach((key) => {
    const query = `(min-width: ${themeBreakpoints[key]}px)`
    const matches = isSSR || window.matchMedia(query).matches
    bps[key] = matches
  })

  return bps as BreakpointsBoolean
}

export const BreakpointsProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [breakpoints, setBreakpoints] =
    useState<BreakpointsBoolean>(getBreakpoints())

  const activeBP = useMemo(
    () =>
      (
        Object.keys(breakpoints).slice().reverse() as Array<
          keyof BreakpointsBoolean
        >
      ).find((key) => breakpoints[key]) || 'xs',
    [breakpoints]
  )

  useEffect(() => {
    const listener = () => setBreakpoints(getBreakpoints())

    window.addEventListener('resize', listener)
    window.addEventListener('DOMContentLoaded', listener)
    return () => {
      window?.removeEventListener('resize', listener)
      window?.removeEventListener('DOMContentLoaded', listener)
    }
  }, [])

  const providerValues = useMemo(
    () => ({ ...breakpoints, activeBreakpoint: activeBP }),
    [activeBP, breakpoints]
  )

  return (
    <BreakpointsContext.Provider value={providerValues}>
      {children}
    </BreakpointsContext.Provider>
  )
}

export default BreakpointsContext
