import React from 'react'

import { ThemeSwitcher } from 'contexts/ThemeContext'
import useThemeSwitcher from 'hooks/useThemeSwitcher'
import { ThemeProvider } from 'styled-components'

interface Props {
  children: React.ReactNode
}

const ThemeWrapper: React.FC<Props> = ({ children }) => {
  const { theme } = useThemeSwitcher()

  return (
    <ThemeSwitcher>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeSwitcher>
  )
}

export default ThemeWrapper
