import { css, createGlobalStyle } from 'styled-components'

import Normalize from 'styles/normalize'

const Global = css`
  * {
    outline-color: ${({ theme }) => theme.colors.primary};
  }

  html {
    font-size: ${({ theme }) => theme.fonts.size};

    background-color: ${({ theme }) => theme.colors.background};
  }

  body {
    font-family: 'Bebas Neue', serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Bebas Neue', serif;
  }

  a {
    text-decoration: none;
  }
`

const GlobalStyles = createGlobalStyle`
    ${Normalize}
    ${Global} 
`

export default GlobalStyles
