import type { Breakpoints, Colors, Fonts } from 'types/theme'

export const breakpoints: Breakpoints = {
  base: 0,
  xs: 400,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  xxxl: 1600,
  huge: 1780,
  growStop: 1920,
}

type ColorsModes = 'accessibility' | 'default'
type ColorsType = (mode?: ColorsModes) => { colors: Colors }
export const colors: ColorsType = (mode = 'default') => {
  const isAccessibility = mode === 'accessibility'

  return {
    accessibilityCollors: isAccessibility,

    colors: {
      white: '#FFFFFF',
      black: '#000000',

      background: isAccessibility ? '#000000' : '#FFFFFF',

      accessibility: {
        yellow: '#ff0',
        pink: '#FF00FF',
      },

      grayscale: {
        100: '#000000',
        80: '#555555',
        60: '#9D9D9D',
        40: '#C4C4C4',
        10: '#D9D9D9',
        5: '#E9E9E9',
        0: '#F5F5F5',
      },
      primary: {
        100: '#90070B',
        50: '#B7191E',
        20: '#E53737',
        10: '#EF5252',
        5: '#FFCDD3',
        0: '#FFEBEE',
      },

      support: {
        success: {
          100: '#116942',
          50: '#1BA769',
          5: '#DEF2E9',
        },
        warning: {
          100: '#A64F21',
          50: '#E86825',
          5: '#FFE1BE',
        },
        alert: {
          100: '#9F1B1F',
          50: '#DE1C22',
          5: '#FFE0E1',
        },
      },

      fonts: {
        white: isAccessibility ? '#ff0' : '#FFFFFF',
        black: isAccessibility ? '#ff0' : '#000000',
        gray80: isAccessibility ? '#ff0' : '#555555',

        primary50: isAccessibility ? '#FF00FF' : '#B7191E',
        white72: '#FFFFFFB8',
      },

      transparent: {
        white: {
          100: '#FFFFFFFF',
          72: '#FFFFFFB8',
          40: '#FFFFFF66',
          24: '#FFFFFF3D',
          12: '#FFFFFF1F',
        },
        black: {
          80: '#555555CC',
          60: '#00000099',
        },
      },
    },
  }
}

type FontsSizes = 1 | 2 | 3
type FontsType = (fontSize?: FontsSizes) => { fonts: Fonts }
export const fonts: FontsType = (fontSize = 1) => {
  const fontSizes: {
    [key in FontsSizes]: Fonts['size']
  } = {
    1: '16px',
    2: '24px',
    3: '32px',
  }

  return {
    fonts: {
      size: fontSizes[fontSize],
    },
  }
}

const theme = {
  breakpoints,
  ...colors(),
  ...fonts(),
}

export default theme
