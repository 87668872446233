/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

const { wrapRootElement, wrapPageElement } = require('./gatsby-shared')

exports.wrapRootElement = wrapRootElement
exports.wrapPageElement = wrapPageElement
