const React = require('react')

require('@fontsource/inter')
require('@fontsource/barlow')
require('@fontsource/bebas-neue')

const { default: GlobalStyles } = require('styles/global')
const { BreakpointsProvider } = require('contexts/BreakpointsContext')

const ThemeWrapper = require('styles/ThemeWrapper').default

exports.wrapRootElement = ({ element }) => {
  return <BreakpointsProvider>{element}</BreakpointsProvider>
}

exports.wrapPageElement = ({ element }) => (
  <ThemeWrapper>
    <GlobalStyles />
    {element}
  </ThemeWrapper>
)
