import React, { createContext } from 'react'

import theme, { fonts, colors } from 'styles/theme'

export const ThemeContext = createContext({
  theme,
  dispatch: (action?: { type: ThemeActions }) => {},
})

export type ThemeActions =
  | 'TOGGLE_COLOR_THEME'
  | 'SET_FONT_SIZE_1'
  | 'SET_FONT_SIZE_2'
  | 'SET_FONT_SIZE_3'

interface Props {
  children: React.ReactNode
}

export const ThemeSwitcher: React.FC<Props> = ({ children }) => {
  const [currentTheme, setCurrentTheme] = React.useState(theme)
  const [colorTheme, setColorTheme] = React.useState<
    'accessibility' | 'default'
  >('default')

  const reducer = (action?: { type: ThemeActions }) => {
    switch (action?.type) {
      case 'TOGGLE_COLOR_THEME':
        setColorTheme((prev) =>
          prev === 'default' ? 'accessibility' : 'default'
        )

        setCurrentTheme((prevTheme) => ({
          ...prevTheme,
          ...colors(colorTheme === 'default' ? 'accessibility' : 'default'),
        }))
        return
      case 'SET_FONT_SIZE_1':
        setCurrentTheme((prevTheme) => ({
          ...prevTheme,
          ...fonts(1),
        }))
        return

      case 'SET_FONT_SIZE_2':
        setCurrentTheme((prevTheme) => ({
          ...prevTheme,
          ...fonts(2),
        }))
        return

      case 'SET_FONT_SIZE_3':
        setCurrentTheme((prevTheme) => ({
          ...prevTheme,
          ...fonts(3),
        }))
        return

      default:
        return
    }
  }

  return (
    <ThemeContext.Provider value={{ theme: currentTheme, dispatch: reducer }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContext
